import { useNavigate } from 'react-router-dom';
import './Headerrow.css';
import { Menu } from '../menu/Menu.tsx';
import { TbMenu2, TbFileText } from "react-icons/tb";


export const Headerrow = () => {
    const navigate = useNavigate();
    const handleClickImpressum = () => navigate('/impressum');
    const handleClickFooterGDPR = () => navigate('/gdpr');
    return (


        <div id="headerrow" data-nosnippet>

            <div id="dropdown">
                <span> <TbMenu2></TbMenu2></span>

                <div id="dropdown-content">
                    <div >
                        <Menu></Menu>
                    </div>
                    <div id="impressum" onClick={() => handleClickImpressum()}>
                        <div id="iconImpressum">
                            <TbFileText></TbFileText>
                        </div>
                        Impressum
                    </div>
                    <div id="impressum" onClick={() => handleClickFooterGDPR()}>
                        <div id="iconImpressum">
                            <TbFileText></TbFileText>
                        </div>
                        GDPR
                    </div>
                </div>
            </div>


            <div id='row'>

                <div >
                    <Menu></Menu>
                </div>

                <div id="impressum" onClick={() => handleClickImpressum()}>
                    <div id="iconImpressum">
                        <TbFileText></TbFileText>
                    </div>
                    Impressum
                </div>
                <div id="impressum" onClick={() => handleClickFooterGDPR()}>
                    <div id="iconImpressum">
                        <TbFileText></TbFileText>
                    </div>
                    GDPR
                </div>
            </div>
        </div>


    )
}