import './Herobarbackground.css';
import React, { useEffect, useRef } from 'react';


export const Herobarbackground = () => {
  const windowWith = window.innerWidth;
  var starCount = 55; // Number of stars to create
  var shootingStarInterval = 2000; // Interval between shooting stars in milliseconds
  var shootingStarStartPoint = 200;

  if (windowWith > 640 && windowWith < 1024) {
    starCount = 30;
    shootingStarInterval = 1000;
    shootingStarStartPoint = 250;
  }

  if (windowWith < 640) {
    starCount = 12;
    shootingStarInterval = 500;
    shootingStarStartPoint = 350;
  }




  const generateStars = () => {
    const stars = [];
    for (let i = 0; i < starCount; i++) {
      stars.push(
        <div
          key={i} // Add a unique "key" prop for each star element
          id="star"
          style={{
            top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`,
          }}
        ></div>
      );
    }
    return stars;
  };
  const shootingStarRef = useRef(null);
  const shootingStarTimeoutRef = useRef(null);
  const shootingStarIntervalIdRef = useRef(null);

  const createShootingStar = () => {
    const starsContainer = document.getElementById("stars");
    if (starsContainer) {
      const shootingStar = document.createElement("div");
      shootingStar.id = "shooting-star";

      shootingStar.style.top = `${Math.random() * shootingStarStartPoint}%`;
      shootingStar.style.left = `${Math.random() * 100}%`;
      starsContainer.appendChild(shootingStar);

      shootingStarRef.current = shootingStar; // Store the reference to the shooting star element

      shootingStarTimeoutRef.current = setTimeout(() => {
        if (shootingStarRef.current && shootingStarRef.current.parentNode === starsContainer) {
          starsContainer.removeChild(shootingStarRef.current);
        }
      }, 3000);
    }
  };

  useEffect(() => {
    const initialShootingStarTimeout = setTimeout(
      () => createShootingStar(),
      Math.random() * shootingStarInterval
    );

    shootingStarIntervalIdRef.current = setInterval(() => {
      createShootingStar();
    }, shootingStarInterval);

    return () => {
      clearTimeout(initialShootingStarTimeout);
      clearInterval(shootingStarIntervalIdRef.current);

      // Clear the shooting star timeout
      clearTimeout(shootingStarTimeoutRef.current);

      // Cleanup the last shooting star if it's still present when unmounting
      const starsContainer = document.getElementById("stars");
      if (starsContainer && shootingStarRef.current && shootingStarRef.current.parentNode === starsContainer) {
        starsContainer.removeChild(shootingStarRef.current);
      }
    };
  }, []);

  return (
    <div id="stars" style={{ position: "absolute" }}>
      {generateStars()}
    </div>
  );
};

