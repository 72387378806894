import React, { useState, lazy, Suspense, useContext, useEffect } from 'react';
import '../Home.css';

import { Headerrow } from '../../../components/headerrow/Headerrow';
import TabButtonHome from '../../../components/tabbuttonHome/TabuttonHome';

import { Header } from '../../../components/header/Header';

import { Loadinganimation } from '../../../components/loader/Loadinganimation';

import { Herobarbackground } from '../../../components/herobarbackground/Herobarbackground'

import { SelectionContext } from '../../../SelectionProvider';


const SliderHobbyEN1 = lazy(() => import("../../../components/slider/en/hobby/SliderHobbyEN1"));
const SliderHobbyEN2 = lazy(() => import("../../../components/slider/en/hobby/SliderHobbyEN2"));

const SliderPetsEN1 = lazy(() => import("../../../components/slider/en/pets/SliderPetsEN1"));
const SliderPetsEN2 = lazy(() => import("../../../components/slider/en/pets/SliderPetsEN2"));

const SliderWorkEN1 = lazy(() => import("../../../components/slider/en/work/SliderWorkEN1"));
const SliderWorkEN2 = lazy(() => import("../../../components/slider/en/work/SliderWorkEN2"));

const SliderStarsEN1 = lazy(() => import("../../../components/slider/en/stars/SliderStarsEN1"));
const SliderStarsEN2 = lazy(() => import("../../../components/slider/en/stars/SliderStarsEN2"));








const HomeEN = () => {
  document.documentElement.lang = "en";

  const { selectedMode, setSelectedMode } = useContext(SelectionContext);

  const mode = isModeSet(selectedMode);

  const [tab, setTab] = useState(mode);

  function isModeSet(selectedMode) {
    if (!selectedMode) {
      return "hobbyRow";
    }
    return selectedMode;
  }



  function calculateYPositionOfDiv(divName) {
    var element = document.getElementById(divName);
    const rect = element.getBoundingClientRect();
    return rect.top + document.documentElement.scrollTop
  }


  function selectTab(nextTab) {
    setTab(nextTab);
    var element = document.getElementById(nextTab);
    element.scrollIntoView({ behavior: "auto", block: "start" });
  }

  const [mainBottom, setMainBottom] = useState(0);
  const [isPastMain, setIsPastMain] = useState(false);


  useEffect(() => {
    selectTab(mode);
    const updateMainBottom = () => {
      const mainElement = document.getElementById('heroBar');
      if (mainElement) {
        const offsetTop = mainElement.offsetTop;
        const height = mainElement.offsetHeight;
        setMainBottom(offsetTop + height);
      }
    };

    const handleScroll = () => {
      const scrollTop = Math.round(window.scrollY);

      let switchPoint = 300;
      let setPoint = 400;


      if (window.innerWidth > 640 && window.innerWidth < 1024) {
        switchPoint = 265;
        setPoint = 400;
      }

      if (window.innerWidth < 640) {
        switchPoint = 170;
        setPoint = 800;
      }


      if (scrollTop > mainBottom + switchPoint) {
        setIsPastMain(true);
      } else {
        setIsPastMain(false);
      }


      if ((scrollTop < calculateYPositionOfDiv('hobbyRow') + setPoint)) {
        setTab('hobbyRow')
        setSelectedMode('hobbyRow');
      }
      if ((calculateYPositionOfDiv('hobbyRow') + setPoint < scrollTop && scrollTop < calculateYPositionOfDiv('petsRow') + setPoint)) {
        setTab('petsRow')
        setSelectedMode('petsRow');
      }
      if ((calculateYPositionOfDiv('petsRow') + setPoint < scrollTop && scrollTop < calculateYPositionOfDiv('workRow') + setPoint)) {
        setTab('workRow')
        setSelectedMode('workRow');
      }

      if ((calculateYPositionOfDiv('workRow') + setPoint < scrollTop)) {
        setTab('starsRow')
        setSelectedMode('starsRow');
      }


    };

    // Initial calculation on mount
    updateMainBottom();

    // Add event listener for scroll
    window.addEventListener('scroll', handleScroll);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [mainBottom]);


  return (

    <div className='App'>

      <Headerrow></Headerrow>
      <Header></Header>

      <div className={`heroBar ${isPastMain ? 'pastMain' : ''}`}>
        <Herobarbackground></Herobarbackground>
        <div id="heroContainer">
          <div id="heroFrame">
            <div className={`heroText ${isPastMain ? 'pastMain' : ''}`}>
              <h1>Uncover your destiny, explore your future, <br></br>and find your path with accurate horoscope predictions</h1>
            </div>
          </div>
          <div id="changeMode">
            <TabButtonHome isActive={tab === 'hobbyRow'} onClick={() => selectTab('hobbyRow')}>Hobby</TabButtonHome>
            <TabButtonHome isActive={tab === 'petsRow'} onClick={() => selectTab('petsRow')}>Pets</TabButtonHome>
            <TabButtonHome isActive={tab === 'workRow'} onClick={() => selectTab('workRow')}>Work</TabButtonHome>
            <TabButtonHome isActive={tab === 'starsRow'} onClick={() => selectTab('starsRow')}>Stars</TabButtonHome>
          </div>
        </div>
      </div>

      <div className={`containerHome ${isPastMain ? 'pastMain' : ''}`}>

        <div id='hobbyRow'>Hobby</div>
        <Suspense fallback={<Loadinganimation></Loadinganimation>}><SliderHobbyEN1 /></Suspense>
        <Suspense fallback={<Loadinganimation></Loadinganimation>}><SliderHobbyEN2 /></Suspense>

        <div id='petsRow'>Pet</div>
        <Suspense fallback={<Loadinganimation></Loadinganimation>}><SliderPetsEN1 /></Suspense>
        <Suspense fallback={<Loadinganimation></Loadinganimation>}><SliderPetsEN2 /></Suspense>

        <div id='workRow'>Work</div>
        <Suspense fallback={<Loadinganimation></Loadinganimation>}><SliderWorkEN1 /></Suspense>
        <Suspense fallback={<Loadinganimation></Loadinganimation>}><SliderWorkEN2 /></Suspense>

        <div id='starsRow'>Stars</div>
        <Suspense fallback={<Loadinganimation></Loadinganimation>}><SliderStarsEN1 /></Suspense>
        <Suspense fallback={<Loadinganimation></Loadinganimation>}><SliderStarsEN2 /></Suspense>

      </div>




    </div >

  );
}
export default HomeEN;
