import React, { createContext, useState } from 'react';

export const SelectionContext = createContext();

const SelectionProvider = ({ children }) => {
  const [selectedMode, setSelectedMode] = useState('');


  return (
    <SelectionContext.Provider
      value={{
        selectedMode,
        setSelectedMode
      }}
    >
      {children}
    </SelectionContext.Provider>
  );
};

export default SelectionProvider;