
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React, { Suspense } from 'react';
import { Loadinganimation } from './components/loader/Loadinganimation';
import { Header } from './components/header/Header';
import SelectionProvider from './SelectionProvider'; 





import HomeEN from './pages/home/en/HomeEN';

const HomeDE = React.lazy(() => import('./pages/home/de/HomeDE'));

const Horoscope = React.lazy(() => import('./pages/horoscopes/Horoscope'));

const Impressum = React.lazy(() => import('./components/RechtlicheBanner/impressum/de/Impressum'));

const DSVGO = React.lazy(() => import('./components/RechtlicheBanner/Datenschutz/de/DSGVO'));

const GDPR = React.lazy(() => import('./components/RechtlicheBanner/Datenschutz/en/GDPR'));

function App() {
  const zodiacEN = [
    { path: '/capricorn' },
    { path: '/aquarius' },
    { path: '/pisces' },
    { path: '/aries' },
    { path: '/taurus' },
    { path: '/gemini' },
    { path: '/cancer' },
    { path: '/leo' },
    { path: '/virgo' },
    { path: '/libra' },
    { path: '/scorpio' },
    { path: '/sagittarius' },
  ];

  const zodiacDE = [
    { path: '/steinbock' },
    { path: '/wassermann' },
    { path: '/fische' },
    { path: '/widder' },
    { path: '/stier' },
    { path: '/zwillinge' },
    { path: '/krebs' },
    { path: '/löwe' },
    { path: '/jungfrau' },
    { path: '/waage' },
    { path: '/skorpion' },
    { path: '/schütze' },
  ];


  return (
    <SelectionProvider>
    <BrowserRouter>
      <Suspense fallback={<><Header></Header><Loadinganimation></Loadinganimation></>}>
    
        <Routes>
          <Route path="/" element={<HomeEN />} />
          <Route path="/home/en" element={<HomeEN />} />
          <Route path="/home/de" element={<HomeDE />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/dsvgo" element={<DSVGO />} />
          <Route path="/gdpr" element={<GDPR />} />
   

          {/* Englisch */}

          {/* Hobby */}
          {/* Slider1 */}

          {zodiacEN.map((route, index) => (
            <Route key={index} path={"/horoscope-photography" + route.path + "/en"} element={<Horoscope />} />
          ))}

          {zodiacEN.map((route, index) => (
            <Route key={index} path={"/horoscope-horsebackriding" + route.path + "/en"} element={<Horoscope />} />
          ))}

          {zodiacEN.map((route, index) => (
            <Route key={index} path={"/horoscope-gardening" + route.path + "/en"} element={<Horoscope />} />
          ))}

          {zodiacEN.map((route, index) => (
            <Route key={index} path={"/horoscope-hiking" + route.path + "/en"} element={<Horoscope />} />
          ))}


          {/* Slider2 */}

          {zodiacEN.map((route, index) => (
            <Route key={index} path={"/horoscope-dancing" + route.path + "/en"} element={<Horoscope />} />
          ))}

          {zodiacEN.map((route, index) => (
            <Route key={index} path={"/horoscope-baking" + route.path + "/en"} element={<Horoscope />} />
          ))}

          {zodiacEN.map((route, index) => (
            <Route key={index} path={"/horoscope-decorate" + route.path + "/en"} element={<Horoscope />} />
          ))}

          {zodiacEN.map((route, index) => (
            <Route key={index} path={"/horoscope-painting" + route.path + "/en"} element={<Horoscope />} />
          ))}




          {zodiacEN.map((route, index) => (
            <Route key={index} path={"/horoscope-fishing" + route.path + "/en"} element={<Horoscope />} />
          ))}



          {/* Pets */}
          {/* Slider1 */}

          {zodiacEN.map((route, index) => (
            <Route key={index} path={"/horoscope-cat" + route.path + "/en"} element={<Horoscope />} />
          ))}

          {zodiacEN.map((route, index) => (
            <Route key={index} path={"/horoscope-dog" + route.path + "/en"} element={<Horoscope />} />
          ))}

          {zodiacEN.map((route, index) => (
            <Route key={index} path={"/horoscope-rabbit" + route.path + "/en"} element={<Horoscope />} />
          ))}

          {zodiacEN.map((route, index) => (
            <Route key={index} path={"/horoscope-bird" + route.path + "/en"} element={<Horoscope />} />
          ))}

          {/* Slider2 */}

          {zodiacEN.map((route, index) => (
            <Route key={index} path={"/horoscope-guineapig" + route.path + "/en"} element={<Horoscope />} />
          ))}

          {zodiacEN.map((route, index) => (
            <Route key={index} path={"/horoscope-turtle" + route.path + "/en"} element={<Horoscope />} />
          ))}

          {zodiacEN.map((route, index) => (
            <Route key={index} path={"/horoscope-hamster" + route.path + "/en"} element={<Horoscope />} />
          ))}

          {zodiacEN.map((route, index) => (
            <Route key={index} path={"/horoscope-fish" + route.path + "/en"} element={<Horoscope />} />
          ))}


          {/* Work */}
          {/* Slider1 */}

          {zodiacEN.map((route, index) => (
            <Route key={index} path={"/horoscope-nurse" + route.path + "/en"} element={<Horoscope />} />
          ))}

          {zodiacEN.map((route, index) => (
            <Route key={index} path={"/horoscope-officeworker" + route.path + "/en"} element={<Horoscope />} />
          ))}

          {zodiacEN.map((route, index) => (
            <Route key={index} path={"/horoscope-hairdresser" + route.path + "/en"} element={<Horoscope />} />
          ))}

          {zodiacEN.map((route, index) => (
            <Route key={index} path={"/horoscope-selfemployed" + route.path + "/en"} element={<Horoscope />} />
          ))}

          {/* Slider2 */}

          {zodiacEN.map((route, index) => (
            <Route key={index} path={"/horoscope-teacher" + route.path + "/en"} element={<Horoscope />} />
          ))}

          {zodiacEN.map((route, index) => (
            <Route key={index} path={"/horoscope-tailor" + route.path + "/en"} element={<Horoscope />} />
          ))}

          {zodiacEN.map((route, index) => (
            <Route key={index} path={"/horoscope-cashier" + route.path + "/en"} element={<Horoscope />} />
          ))}

          {zodiacEN.map((route, index) => (
            <Route key={index} path={"/horoscope-barista" + route.path + "/en"} element={<Horoscope />} />
          ))}



          {/* Star */}
          {/* Slider1 */}

          {zodiacEN.map((route, index) => (
            <Route key={index} path={"/horoscope-taylorswift" + route.path + "/en"} element={<Horoscope />} />
          ))}

          {zodiacEN.map((route, index) => (
            <Route key={index} path={"/horoscope-edsheeran" + route.path + "/en"} element={<Horoscope />} />
          ))}

          {zodiacEN.map((route, index) => (
            <Route key={index} path={"/horoscope-mileycyrus" + route.path + "/en"} element={<Horoscope />} />
          ))}

          {zodiacEN.map((route, index) => (
            <Route key={index} path={"/horoscope-beyonce" + route.path + "/en"} element={<Horoscope />} />
          ))}

          {/* Slider2 */}

          {zodiacEN.map((route, index) => (
            <Route key={index} path={"/horoscope-harrystyles" + route.path + "/en"} element={<Horoscope />} />
          ))}

          {zodiacEN.map((route, index) => (
            <Route key={index} path={"/horoscope-britneyspears" + route.path + "/en"} element={<Horoscope />} />
          ))}

          {zodiacEN.map((route, index) => (
            <Route key={index} path={"/horoscope-rihanna" + route.path + "/en"} element={<Horoscope />} />
          ))}

          {zodiacEN.map((route, index) => (
            <Route key={index} path={"/horoscope-leonardodicaprio" + route.path + "/en"} element={<Horoscope />} />
          ))}



          {/* Detutsch Deutsch Deutsch Deutsch Deutsch Deutsch Deutsch Deutsch Detutsch Deutsch */}

          {/* Hobby */}
          {/* Slider1 */}

          {zodiacDE.map((route, index) => (
            <Route key={index} path={"/horoskop-fotografie" + route.path + "/de"} element={<Horoscope />} />
          ))}

          {zodiacDE.map((route, index) => (
            <Route key={index} path={"/horoskop-reitsport" + route.path + "/de"} element={<Horoscope />} />
          ))}

          {zodiacDE.map((route, index) => (
            <Route key={index} path={"/horoskop-gartenarbeit" + route.path + "/de"} element={<Horoscope />} />
          ))}

          {zodiacDE.map((route, index) => (
            <Route key={index} path={"/horoskop-wandern" + route.path + "/de"} element={<Horoscope />} />
          ))}

          {/* Slider */}

          {zodiacDE.map((route, index) => (
            <Route key={index} path={"/horoskop-tanzen" + route.path + "/de"} element={<Horoscope />} />
          ))}

          {zodiacDE.map((route, index) => (
            <Route key={index} path={"/horoskop-backen" + route.path + "/de"} element={<Horoscope />} />
          ))}

          {zodiacDE.map((route, index) => (
            <Route key={index} path={"/horoskop-dekorieren" + route.path + "/de"} element={<Horoscope />} />
          ))}

          {zodiacDE.map((route, index) => (
            <Route key={index} path={"/horoskop-malen" + route.path + "/de"} element={<Horoscope />} />
          ))}


          {zodiacDE.map((route, index) => (
            <Route key={index} path={"/horoskop-angeln" + route.path + "/de"} element={<Horoscope />} />
          ))}

          {/* Pets */}
          {/* Slider1 */}

          {zodiacDE.map((route, index) => (
            <Route key={index} path={"/horoskop-katze" + route.path + "/de"} element={<Horoscope />} />
          ))}

          {zodiacDE.map((route, index) => (
            <Route key={index} path={"/horoskop-hund" + route.path + "/de"} element={<Horoscope />} />
          ))}

          {zodiacDE.map((route, index) => (
            <Route key={index} path={"/horoskop-hase" + route.path + "/de"} element={<Horoscope />} />
          ))}

          {zodiacDE.map((route, index) => (
            <Route key={index} path={"/horoskop-vogel" + route.path + "/de"} element={<Horoscope />} />
          ))}


          {/* Slider2 */}
          {zodiacDE.map((route, index) => (
            <Route key={index} path={"/horoskop-meerschweinchen" + route.path + "/de"} element={<Horoscope />} />
          ))}

          {zodiacDE.map((route, index) => (
            <Route key={index} path={"/horoskop-schildkröte" + route.path + "/de"} element={<Horoscope />} />
          ))}

          {zodiacDE.map((route, index) => (
            <Route key={index} path={"/horoskop-hamster" + route.path + "/de"} element={<Horoscope />} />
          ))}

          {zodiacDE.map((route, index) => (
            <Route key={index} path={"/horoskop-fisch" + route.path + "/de"} element={<Horoscope />} />
          ))}

          {/* Arbeit */}
          {/* Slider1 */}

          {zodiacDE.map((route, index) => (
            <Route key={index} path={"/horoskop-krankenpflege" + route.path + "/de"} element={<Horoscope />} />
          ))}

          {zodiacDE.map((route, index) => (
            <Route key={index} path={"/horoskop-büroarbeit" + route.path + "/de"} element={<Horoscope />} />
          ))}

          {zodiacDE.map((route, index) => (
            <Route key={index} path={"/horoskop-friseur" + route.path + "/de"} element={<Horoscope />} />
          ))}

          {zodiacDE.map((route, index) => (
            <Route key={index} path={"/horoskop-selbstständig" + route.path + "/de"} element={<Horoscope />} />
          ))}

          {/* Slider2 */}

          {zodiacDE.map((route, index) => (
            <Route key={index} path={"/horoskop-lehrer" + route.path + "/de"} element={<Horoscope />} />
          ))}

          {zodiacDE.map((route, index) => (
            <Route key={index} path={"/horoskop-schneiderei" + route.path + "/de"} element={<Horoscope />} />
          ))}

          {zodiacDE.map((route, index) => (
            <Route key={index} path={"/horoskop-einzelhandel" + route.path + "/de"} element={<Horoscope />} />
          ))}

          {zodiacDE.map((route, index) => (
            <Route key={index} path={"/horoskop-barista" + route.path + "/de"} element={<Horoscope />} />
          ))}

          {/* Star */}
          {/* Slider1 */}

          {zodiacDE.map((route, index) => (
            <Route key={index} path={"/horoskop-taylorswift" + route.path + "/de"} element={<Horoscope />} />
          ))}

          {zodiacDE.map((route, index) => (
            <Route key={index} path={"/horoskop-edsheeran" + route.path + "/de"} element={<Horoscope />} />
          ))}

          {zodiacDE.map((route, index) => (
            <Route key={index} path={"/horoskop-mileycyrus" + route.path + "/de"} element={<Horoscope />} />
          ))}

          {zodiacDE.map((route, index) => (
            <Route key={index} path={"/horoskop-beyonce" + route.path + "/de"} element={<Horoscope />} />
          ))}

          {/* Slider2 */}

          {zodiacDE.map((route, index) => (
            <Route key={index} path={"/horoskop-harrystyles" + route.path + "/de"} element={<Horoscope />} />
          ))}

          {zodiacDE.map((route, index) => (
            <Route key={index} path={"/horoskop-britneyspears" + route.path + "/de"} element={<Horoscope />} />
          ))}

          {zodiacDE.map((route, index) => (
            <Route key={index} path={"/horoskop-rihanna" + route.path + "/de"} element={<Horoscope />} />
          ))}

          {zodiacDE.map((route, index) => (
            <Route key={index} path={"/horoskop-leonardodicaprio" + route.path + "/de"} element={<Horoscope />} />
          ))}


        </Routes>
      </Suspense>
    </BrowserRouter>
    </SelectionProvider>
  );
}

export default App;
