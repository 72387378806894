
import './Menu.css';
import { TbLanguage } from "react-icons/tb";

import { useLocation, useNavigate } from 'react-router-dom';
// @ts-ignore
import { LANGUAGES } from '../../constants/constants.ts';
import { useTranslation } from "react-i18next";
import React, { } from 'react';



export const Menu = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { i18n, t } = useTranslation();


    const onChangeLang = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const lang_code = e.target.value
        const path = decodeURIComponent(location.pathname);
     


        i18n.loadLanguages(lang_code, () => {
            const newUrl = dafaqIsThisMethodEinfachDamitJedeUrlInDerJeweiligenSpracheSeinKann(path, lang_code);
            navigate(newUrl);
            navigate(0);
        });


    }


    function dafaqIsThisMethodEinfachDamitJedeUrlInDerJeweiligenSpracheSeinKann(path: string, lang_code: string) {
        // irgendeine sprache->english->anderesprache
        const splitedPath = path.split("/");

        if (path === "/") {
            return "/home/" + lang_code;
        }

        if (splitedPath.length === 3) {

            i18n.changeLanguage(splitedPath[2]);

            const splitedSplitedPath = splitedPath[1].split("-");
            const firstPartTranslated = t(splitedSplitedPath[0]);
            const secondPartTranslated = t(splitedSplitedPath[1]);

            i18n.changeLanguage(lang_code);

            if (splitedSplitedPath.length === 2) {

                return "/" + t(firstPartTranslated) + "-" + t(secondPartTranslated) + "/" + lang_code;

            }

            //geht hier nur bei home raus
            return "/" + splitedSplitedPath + "/" + lang_code;

        }


        if (splitedPath.length === 4) {
            i18n.changeLanguage(splitedPath[3]);

            const splitedSplitedPath = splitedPath[1].split("-");

            const firstPartTranslated = t(splitedSplitedPath[0]);
            const secondPartTranslated = t(splitedSplitedPath[1]);
            const thirdPartTranslated = t(splitedPath[2]);

            i18n.changeLanguage(lang_code);

            return "/" + t(firstPartTranslated) + "-" + t(secondPartTranslated) + "/" + t(thirdPartTranslated) + "/" + lang_code;
        }

        return "/";

    }


    function getCurrentLanguage() {
        const path = location.pathname;
        if(path=="/")
        {
            return "en";
        }
        return path.slice(-2);
    }



    return (
        <div id='containerMenuLanguage'>

            <div id='menuIcon'> <TbLanguage></TbLanguage></div>

            <select className='menuLanguage' defaultValue={getCurrentLanguage()} onChange={onChangeLang} >
                {
                    LANGUAGES.map(({ code, label }: { code: string, label: string }) => (<option key={code} value={code}>{label}</option>))

                }
            </select>
        </div>
    )
}