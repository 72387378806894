import './TabbuttonHome.css';
export default function TabButton({ children, isActive, onClick }) {
  if (isActive) {
    return <div id="tabSelectedHome" >
      <div id="tabSelectedTextHome">
        {children}
      </div>
    </div>
  }
  return (
    <div id="tabUnselectedHome" onClick={() => {
      onClick();
    }}>
      <div id="tabUnselectedTextHome">
        {children}
      </div>

    </div>
  )
}
