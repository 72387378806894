
import React, { } from 'react';


import './Header.css';

import { useNavigate, useLocation } from 'react-router-dom';


export const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();


    function goToHomeScreen() {
        const path = location.pathname;
        const newLocation = path.slice(-2);

        if (!path.includes("home")) {
            navigate('/home/' + newLocation);
        }
    }

    return (
        <div id='header' onClick={goToHomeScreen} data-nosnippet>
            <div id='horoscopes'>Horoscopes</div>
            <div id='containerImg'>
                <div id='forYou' >For You</div>
                <div id='img'></div>
            </div>
        </div>
    )
}