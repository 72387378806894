
import './Loadinganimation.css';

export const Loadinganimation = () => {

    return (
        <div id="loading-area">
        <div id="loader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>

    )
}
export default Loadinganimation;

